<template>
  <div>
    <transition :name="isMd ? 'expand' : 'slide-in'">
      <div v-if="active" class="right-sidebar-modal">
        <div class="right-sidebar-modal-wrapper">
          <div v-click-outside="onClickOutside" class="right-sidebar-modal-container">
            <div class="right-sidebar-modal-container-close" data-test-id="stock-detail-close" @click="close()">
              <div class="icon-close u-bg-dark-blue" />
            </div>

            <div class="right-sidebar-modal-container-content">
              <slot name="content" />
            </div>
          </div>
        </div>
      </div>
    </transition>
    <div v-show="active" class="right-sidebar-modal-backdrop" @click="close()" />
  </div>
</template>

<script setup>
defineProps({
  active: {
    type: Boolean,
    default: false,
  },
  hasActions: {
    type: Boolean,
    default: true,
  },
})
const { isMd } = useDeviceSize()
const emit = defineEmits(['close'])
const preventClickOutside = ref(false)
onMounted(() => {
  window.addEventListener('mousedown', onMouseDown, { passive: true })
})
onBeforeUnmount(() => {
  window.removeEventListener('mousedown', onMouseDown)
})
const close = () => {
  const { $bus } = useNuxtApp()
  $bus.emit('closeRightSidebar')
  emit('close')
}
const onClickOutside = () => {
  if (preventClickOutside.value) {
    preventClickOutside.value = false
    return
  }
  close()
}
const onMouseDown = mouseEvent => {
  const wrapper = document.querySelector('.right-sidebar-modal-wrapper')
  preventClickOutside.value = mouseEvent?.target !== wrapper
}
</script>

<style lang="scss">
.right-sidebar-modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  background-color: #000;
  opacity: 0.25;
}

.eighty-opacity-backdrop {
  .right-sidebar-modal-backdrop {
    opacity: 0.8;
  }
}

.right-sidebar-modal {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 10000;

  @include mq($mq-md) {
    height: calc(100% - 5rem);
    bottom: 0;
    top: inherit;
    &-container {
      border-top-right-radius: $spacing-sm;
      border-top-left-radius: $spacing-sm;
      overflow: hidden;
    }
  }

  &-wrapper {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: 100%;
  }

  &-container {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 364px;
    background-color: var(--white);
    box-shadow: 0px 5px 30px rgba(5, 23, 47, 0.15);

    @include mq($mq-sm) {
      width: 100%;
    }

    &-close {
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: pointer;
      z-index: 1;
    }

    &-header {
      flex-shrink: 0;
      min-height: 68px;
      border-bottom: 2px solid var(--light-grey);
      padding: 20px;

      &-title {
        display: flex;
        justify-content: space-between;
        font-family: 'Avenir';
        font-weight: 700;
        font-size: pxToRem(20px);
        color: var(--dark-blue);
        padding-right: 25px;

        @include mq($mq-md) {
          padding-top: 0;
        }
      }

      &-info {
        padding-top: 11px;
        font-family: 'Avenir';
        font-weight: 500;
        font-size: pxToRem(15px);
        line-height: 20px;
        color: var(--primary);

        &-buttons {
          display: flex;
          flex-wrap: wrap;
          gap: 10px 20px;
        }
      }

      /* &-button {
        margin: 20px;
      } */
    }

    &-content {
      flex-grow: 1;
      overflow-y: auto;

      &-container {
        position: relative;
        display: flex;
        flex-direction: column;
        height: 100%;
      }
    }

    &-actions {
      display: flex;
      justify-content: flex-end;
      padding: 20px;
      border-top: 2px solid var(--light-grey);
    }
  }
}

.slide-in-enter-active,
.slide-in-leave-active {
  transition: transform 0.3s ease;
}
.slide-in-enter-from,
.slide-in-leave-to {
  transform: translateX(364px);
}

.expand-enter-active,
.expand-leave-active {
  transition: max-height 0.3s ease;
  max-height: calc(100% - 5rem);
}

.expand-enter-from,
.expand-leave-to {
  max-height: 0;
  overflow: hidden;
}
</style>
